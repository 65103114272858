import React from "react";
import Palatul from "../images/palatul.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function ContactForm() {
  const onSubmit = () => {
    alert("submitted");
  };

  const lang = localStorage.getItem("lang")
  ? localStorage.getItem("lang")
  : "ro";

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please provide your first name")
      .max(100, "You have exceeded the character limit"),
    lastName: Yup.string()
      .required("Please provide your last name")
      .max(100, "You have exceeded the character limit"),
    jobTitle: Yup.string().max(100, "You have exceeded the character limit"),
    companyName: Yup.string().max(100, "You have exceeded the character limit"),
    email: Yup.string()
      .email("Invalid format")
      .required("Please provide your email address"),
    message: Yup.string()
      .required("Please let us know your reason for contacting us")
      .max(750, "You have exceeded the character limit"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      jobTitle: "",
      companyName: "",
      email: "",
      message: "",
    },
    validationSchema,
    handleSubmit: onSubmit,
  });

  return (
    <div className="contact-form-container">
      <a id="contact-form"></a>
      <section className="events pt-5 d-flex justify-content-center align-items-lg-center mb-5">
        <div className="container" style={{background:'rgba(255,255,255,0.8)', padding:'10px'}}>
          <div className="w-100">
            <h2 className="text-center mb-5">{lang == "ro" ? "Contacteaza-Ne" : "Contact Us"}</h2>
            <div className="row">
              <div className="col-12 align-self-center mt-3 mt-lg-0">
                <form onSubmit={formik.handleSubmit}>
                  <div className="form-group row">
                    <div className="col-12 col-md-6">
                      {" "}
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name*"
                        className="form-control my-1"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <p className="text-danger" style={{fontSize:'12px'}}>{formik.errors.firstName}</p>
                      ) : null}
                    </div>

                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name*"
                        className="form-control my-1"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <p className="text-danger" style={{fontSize:'12px'}}>{formik.errors.lastName}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        placeholder="Job Title"
                        className="form-control my-1"
                        value={formik.values.jobTitle}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.jobTitle && formik.errors.jobTitle ? (
                        <p className="text-danger" style={{fontSize:'12px'}}>{formik.errors.jobTitle}</p>
                      ) : null}
                    </div>

                    <div className="col-12 col-md-6">
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        placeholder="Company Name"
                        className="form-control my-1"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <p className="text-danger" style={{fontSize:'12px'}}>{formik.errors.companyName}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-12">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        value={formik.values.email}
                        className="form-control my-1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className="text-danger" style={{fontSize:'12px'}}>{formik.errors.email}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-12">
                      <textarea
                        type="text"
                        name="message"
                        id="message"
                        placeholder="Leave a message here..."
                        value={formik.values.message}
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.message && formik.errors.message ? (
                        <p className="text-danger" style={{fontSize:'12px'}}>{formik.errors.message}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-100 petiti">
                    <button className="btn btn-lg text-white">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
