import React from "react";
import Cursuriimg from "../images/invitatie-cursuri.jpg";

export default function Cursuri() {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";

  return (
    <div>
      <a id="petiti"></a>
      <section className="events pt-5 d-flex justify-content-center align-items-lg-center petiti ">
        <div
          className="container"
          style={{ background: "rgba(255,255,255,0.8)", padding: "10px" }}
        >
          <div className="w-100">
            <h2 className="text-center mb-5">{lang == "ro" ? 'Cursuri' : 'Courses'}</h2>
            <div className="row">
              <div className="col-12">
                <img
                  className="petiti-image"
                  src={Cursuriimg}
                  alt="Invitatie Cursuri"
                />
                <p className="text-center">
                  {lang == "ro" ? (
                    <strong>
                      Dacă sunteți interesați să participați sau vreți să fiți
                      anunțați despre următoarele cursuri, contactați-ne{" "}
                      <a href="#contact-form">aici</a>
                    </strong>
                  ) : (
                    <strong>
                      If you are interested in participating or want to be
                      notified about the following courses, contact us {" "}
                      <a href="#contact-form">here</a>
                    </strong>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
