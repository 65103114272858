import React from "react";
import PentruMama from "../images/Screenshot_2.png";
import Prima from "../images/Screenshot_5.png";
import Digi from "../images/Screenshot_3.png";
import Map from "../images/Screenshot_4.png";

// import

export default function Articole() {
  const articles = [
    {
      title: "Canabis pentru Mama!",
      link:
        "https://www.certitudinea.ro/articole/presa/view/canabis-pentru-mama",
      image: PentruMama,
    },
    {
      title: "Primul pas către legalizarea canabisului medicinal",
      link:
        "https://mobile.hotnews.ro/stire/23517368?fbclid=IwAR0QbrVKpG-Sc51YlYl1r80q9KLGmH4MFhSMpjbZPFYFqmWQwJRbzLYmTaY",
      image: Prima,
    },
    {
      title: "Dezbaterea proiectul legislativ Victoria",
      link:
        "https://www.digi24.ro/emisiuni/jurnale/jurnal-ora-17-00-5-ianuarie-4-1240910?fbclid=IwAR3yvIXNNEw4AHdHcYmTRwVoaXSXMEBpNnn123tN73Jt4bclNFBWWvhZFG4",
      image: Digi,
    },
    {
      title: "Peste 40 de state au legalizat canabisul in scop de medicinal",
      link:
        "https://www.google.com/url?sa=D&q=https://stirileprotv.ro/stiri/sanatate/rudele-bolnavilor-de-cancer-apeleaza-la-piata-neagra-pentru-a-le-obtine-medicamente-cu-canabis.html&ust=1611582060000000&usg=AOvVaw0cT7sV1K_Sucuexw4IFpz_&hl=en-GB&source=gmail",
      image: Map,
    },
  ];

  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";

  return (
    <div className="articles-container py-5">
      <h2>{lang == "ro" ? "Articole" : "Articles"}</h2>
      <br />

      <div className="row">
        {articles.map((item) => (
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="media-item">
              <a href={item.link} target="_blank">
                <img src={item.image} />
                <span>{item.title}</span>
              </a>
            </div>
          </div>
        ))}
        <div className="col-12 mt-3">
          {lang == "ro" ? "Apasati" : "Click"}
          <a href="/media.docx" download>
           {" "} {lang == "ro" ? "aici" : "here"}
          </a>{" "}
          {lang == "ro" ? "pentru alte articule" : "for more articles"}
        </div>
      </div>
    </div>
  );
}
