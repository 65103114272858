import React, { useState } from "react";
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import Plant from "../images/education.png";
import Voluntari from "../images/volunteer.png";
import Actiuni from "../images/members.png";
import Fade from "react-reveal/Fade";
export default () => {
  const [activeItem, setactiveItem] = useState("1");
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";

  return (
    <div>
      <a id="despre-noi"></a>
      <section className="align-items-center about-us pt-5 d-flex justify-content-center bg-lightgrey">
        <div
          className="container"
          style={{
            color: "black",
            padding: "1rem",
            background: "rgba(255,255,255,0.8)",
            borderRadius: "5px",
          }}
        >
          {lang == "ro" ? (
            <div className="w-100">
              <Fade bottom>
                <h2 className="text-center mb-5">Despre Noi</h2>
                <p className="text-justify">
                  Asociația Victoria Mea s-a înființat în anul 2019 pentru a
                  ajuta organizațiile de pacienți, bolnavii și pe cei interesați
                  să se informeze corect asupra beneficiilor canabisului, pentru
                  a beneficia de tratament legal, în țara noastră, pentru a
                  reuni comunitățile medicale și științifice din tara noastră,
                  interesate de această terapie, pentru a crea o punte de
                  legătură cu alte asociații similare care militează pentru
                  același scop, din Europa și din lume, pentru a reuni expertiza
                  știintifică din alte state și pentru a încuraja cercetarea și
                  inovația și în țara noastră. Toate acestea în beneficiul
                  pacienților, pentru a contribui la reglementarea corectă a
                  canabisului medicinal în România și pentru a deveni o voce
                  credibilă, onestă și avizată a celor care au nevoie să-și
                  exercite dreptul la viată și la tratament.
                </p>
              </Fade>

              <MDBContainer>
                <MDBNav className="nav-tabs mt-5 d-flex justify-content-center align-items-end">
                  <MDBNavItem className="despre-noi-tab-item">
                    <MDBNavLink
                      link
                      to="#"
                      active={activeItem === "1"}
                      onClick={() => setactiveItem("1")}
                      role="tab"
                    >
                      <h6 className="my-3 d-flex align-items-center">
                        <img src={Plant} width="60px" />
                        <span>Educație și comunicare</span>
                      </h6>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem className="despre-noi-tab-item">
                    <MDBNavLink
                      link
                      to="#"
                      active={activeItem === "2"}
                      onClick={() => setactiveItem("2")}
                      role="tab"
                    >
                      <h6 className="my-3 d-flex align-items-center">
                        {" "}
                        <img src={Voluntari} width="60px" />
                        <span>Voluntariat și dialog social</span>{" "}
                      </h6>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem className="despre-noi-tab-item">
                    <MDBNavLink
                      link
                      to="#"
                      active={activeItem === "3"}
                      onClick={() => setactiveItem("3")}
                      role="tab"
                    >
                      <h6 className="my-3 d-flex align-items-center">
                        <img src={Actiuni} width="60px" />
                        <span>
                          Acțiuni destinate membrilor și simpatizanților
                        </span>
                      </h6>
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={activeItem}>
                  <MDBTabPane tabId="1" role="tabpanel">
                    <ul className="list-unstyled tab-details-list">
                      {" "}
                      <li>
                        {" "}
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Asigurăm informarea corectă în privința terapiilor
                        medicale pe bază de canabis;
                      </li>
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Conducem și organizăm dezbateri publice, cu scopul
                        informării și facilitării accesului la informații și
                        terapii din întreaga lume;
                      </li>
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Organizăm sesiuni științifice pentru profesioniști din
                        domeniul medical, dar și pentru antreprenori interesati
                        de acest domeniu nou;
                      </li>
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Invităm cadre universitare, specialiști în drept și
                        experți medicali în țara noastră pentru a facilita
                        transmiterea unor informații serioase și corecte.
                      </li>
                    </ul>
                  </MDBTabPane>
                  <MDBTabPane tabId="2" role="tabpanel">
                    <ul className="list-unstyled tab-details-list">
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Susținem un dialog între instituțiile statului român,
                        instituții europene, centre educaționale și științifice
                        din Europa, universități și cadre științifice din
                        România, asociații de pacienți din țara noastră și
                        pacienți din alte state din lume;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Încurajăm schimbul instituțional de bune practici
                        legislative, precum și implementarea unor programe
                        naționale și europene în domeniul canabisului medicinal;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Ne propunem parteneriate și colaborări cu diverse foruri
                        europene în domeniul canabisului medicinal, pentru a
                        ajuta ca țara noastră să ia parte la deciziile
                        definitorii în domeniul sănătății pacienților;
                      </li>

                      <li>
                        {" "}
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>
                        Încurajăm voluntariatul și susținem implicarea activă a
                        societății civile;
                      </li>
                    </ul>
                  </MDBTabPane>
                  <MDBTabPane tabId="3" role="tabpanel">
                    <ul className="list-unstyled tab-details-list">
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Ne erijăm în vehicul prin care schimbul de informații și
                        oportunități de informare, cercetare sau antreprenoriat
                        să poată fi posibil între membrii unor organizații cu
                        scop similar din Europa și din întreaga lume;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Informăm constant printr-un buletin informativ asupra
                        politicilor europene și internaționale, precum și asupra
                        evenimentelor de interes din domeniu;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Consiliem membrii și pe cei interesați în privința
                        oportunităților de educație, cercetare și finanțare
                        europeană și națională, precum și identificăm potențiali
                        parteneri din țară și străinătate;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        Încurajăm în permanență transparența și corectitudinea
                        analizării și verificării produselor destinate uzului
                        medical de pe piață, prin contribuirea la analiza
                        produselor care se comercializează.
                      </li>
                      <li className="petiti">
                        <a href="#contact-form">
                          <button className="btn btn-lg text-white">
                            Contactează-ne
                          </button>
                        </a>
                      </li>
                    </ul>
                  </MDBTabPane>
                </MDBTabContent>
              </MDBContainer>
            </div>
          ) : (
            <div className="w-100">
              <Fade bottom>
                <h2 className="text-center mb-5">About us</h2>
                <p className="text-justify">
                  Victoria Mea Association was founded in 2019 to help
                  organizations of patients, patients and those interested to
                  learn correctly about the benefits of cannabis, to benefit
                  from legal treatment in our country, to bring together the
                  medical and scientific communities in our country, interested
                  in this therapy, to create a bridge with other similar
                  associations that militate for the same purpose, in Europe and
                  in the world, to bring together scientific expertise from
                  other countries and to encourage research and innovation in
                  our country as well. All this for the benefit of patients, in
                  order to contribute to the correct regulation of medicinal
                  cannabis in Romania and to become a credible, honest and
                  informed voice of those who need to exercise their right to
                  live and equal treatment and access to new therapies and
                  treatments.
                </p>
              </Fade>

              <MDBContainer>
                <MDBNav className="nav-tabs mt-5 d-flex justify-content-center align-items-end">
                  <MDBNavItem className="despre-noi-tab-item">
                    <MDBNavLink
                      link
                      to="#"
                      active={activeItem === "1"}
                      onClick={() => setactiveItem("1")}
                      role="tab"
                    >
                      <h6 className="my-3 d-flex align-items-center">
                        <img src={Plant} width="60px" />
                        <span>Education and Communication</span>
                      </h6>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem className="despre-noi-tab-item">
                    <MDBNavLink
                      link
                      to="#"
                      active={activeItem === "2"}
                      onClick={() => setactiveItem("2")}
                      role="tab"
                    >
                      <h6 className="my-3 d-flex align-items-center">
                        {" "}
                        <img src={Voluntari} width="60px" />
                        <span>Volunteering and Social dialogue</span>{" "}
                      </h6>
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem className="despre-noi-tab-item">
                    <MDBNavLink
                      link
                      to="#"
                      active={activeItem === "3"}
                      onClick={() => setactiveItem("3")}
                      role="tab"
                    >
                      <h6 className="my-3 d-flex align-items-center">
                        <img src={Actiuni} width="60px" />
                        <span>Actions for Members and Supporters</span>
                      </h6>
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={activeItem}>
                  <MDBTabPane tabId="1" role="tabpanel">
                    <ul className="list-unstyled tab-details-list">
                      {" "}
                      <li>
                        {" "}
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We provide accurate information on cannabis-based
                        medical therapies;
                      </li>
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We lead and organize public debates, in order to inform
                        and facilitate access to information and therapies
                        around the world;
                      </li>
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We organize scientific sessions for medical
                        professionals, but also for entrepreneurs interested in
                        this new field;
                      </li>
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We invite academics, legal specialists and medical
                        experts in our country to facilitate the transmission of
                        serious and correct information.
                      </li>
                    </ul>
                  </MDBTabPane>
                  <MDBTabPane tabId="2" role="tabpanel">
                    <ul className="list-unstyled tab-details-list">
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We support a dialogue between Romanian state
                        institutions, European institutions, educational and
                        scientific centers in Europe, universities and
                        scientific staff in Romania, patient associations in our
                        country and patients from other countries in the world;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We encourage the institutional exchange of good
                        legislative practices, as well as the implementation of
                        national and European programs in the field of medical
                        cannabis;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We propose partnerships and collaborations with various
                        European forums in the field of medical cannabis, to
                        help our country take part in the defining decisions in
                        the field of patient health;
                      </li>

                      <li>
                        {" "}
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>
                        We encourage volunteering and support the active
                        involvement of civil society;
                      </li>
                    </ul>
                  </MDBTabPane>
                  <MDBTabPane tabId="3" role="tabpanel">
                    <ul className="list-unstyled tab-details-list">
                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We stand in the vehicle through which the exchange of
                        information and opportunities for information, research
                        or entrepreneurship can be made possible between members
                        of organizations with a similar purpose in Europe and
                        around the world;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We constantly inform through a newsletter on European
                        and international policies, as well as on events of
                        interest in the field;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We advise members and those interested in European and
                        national education, research and funding opportunities,
                        as well as identify potential partners in the country
                        and abroad;
                      </li>

                      <li>
                        <span
                          style={{ fontSize: "8px", verticalAlign: "text-top" }}
                        >
                          ⚫
                        </span>{" "}
                        We constantly encourage the transparency and correctness
                        of the analysis and verification of products intended
                        for medical use on the market, by contributing to the
                        analysis of the products that are marketed.
                      </li>
                      <li className="petiti">
                        <a href="#contact-form">
                          <button className="btn btn-lg text-white">
                            Contact us
                          </button>
                        </a>
                      </li>
                    </ul>
                  </MDBTabPane>
                </MDBTabContent>
              </MDBContainer>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
