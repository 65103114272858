import React from "react";
import Slider from "react-slick";
// import Site from "../images/invitatie-site.png";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export default function Petiti() {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";
  return (
    <div>
      <a id="petiti"></a>
      <section className="events pt-5 d-flex justify-content-center align-items-center bg-lightgrey petiti ">
        <div
          className="container"
          style={{ background: "rgba(255,255,255,0.8)", color: "black" }}
        >
          {lang == "ro" ? (
            <div className="w-100">
              <h2 className="text-center mt-3 mb-3">
                Petitie pentru legalizarea canabisului medicinal
              </h2>
              <p className="text-center">
                {" "}
                <a
                  target="blank"
                  href="http://www.cdep.ro/pls/proiecte/upl_pck2015.proiect?idp=18223"
                  style={{
                    fontSize: "20px",
                    color: "black",
                  }}
                >
                  PL-x nr. 631/2019
                </a>{" "}
              </p>
              {/* <div className="row">
                 <div className="col-12 align-self-center mt-3 mt-lg-0">
                   <img className="petiti-image"  src={Site} alt="Invitatie Site" width="100" />
                 </div>
                 
               </div> */}

              <div className="mt-5"></div>

              <Slider {...settings}>
                <div className="slider-box">
                  <p>
                    {" "}
                    <sup>
                      <i class="fa fa-quote-left" style={{ fontSize: 22 }}></i>
                    </sup>
                    &nbsp; Pentru sansa la viata si sanatate. Pentru dreptul
                    uman
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    -Carmen Negoescu
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    România Bucuresti
                  </p>
                </div>
                <div className="slider-box">
                  <p>
                    {" "}
                    <sup>
                      <i class="fa fa-quote-left" style={{ fontSize: 22 }}></i>
                    </sup>
                    &nbsp;Semnez, pentru ca acest tratament este necesar
                    bolnavilor de cancer.
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    -Radu Șleam
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    România Baia Mare
                  </p>
                </div>

                <div className="slider-box">
                  <p>
                    {" "}
                    <sup>
                      <i class="fa fa-quote-left" style={{ fontSize: 22 }}></i>
                    </sup>
                    &nbsp; Semnez pentru ca este absurd ca o planta care poate
                    face atat de mult bine si care poate alina dureri este
                    ilegala, insa este perfect legal sa fim indopati cu pastile
                    care, pe termen lung, pot produce mai mult rau decat bine.
                    Semnez pentru ca este absurd sa fie ilegala o planta care nu
                    a omorat nici macar o persoana, niciodata, insa tutunul si
                    alcoolul, care fac mii de victime anual, sunt legale. Semnez
                    in speranta unei schimbari!
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    -Lucian Constantin Sofronie
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    România Stefanesti
                  </p>
                </div>
              </Slider>

              <br />
              <div className="row">
                <div className="col-12 my-5">
                  <a
                    href="https://www.petitieonline.com/canabis_in_scop_medicinal_in_romania"
                    target="_blank"
                  >
                    <button className="btn btn-lg text-white">Semneaza</button>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100">
              <h2 className="text-center mt-3 mb-3">
                Petition for legalization of medicinal cannabis
              </h2>
              <p className="text-center">
                {" "}
                <a
                  target="blank"
                  href="http://www.cdep.ro/pls/proiecte/upl_pck2015.proiect?idp=18223"
                  style={{
                    fontSize: "20px",
                    color: "black",
                  }}
                >
                  PL-x nr. 631/2019
                </a>{" "}
              </p>
              {/* <div className="row">
               <div className="col-12 align-self-center mt-3 mt-lg-0">
                 <img className="petiti-image"  src={Site} alt="Invitatie Site" width="100" />
               </div>
               
             </div> */}

              <div className="mt-5"></div>

              <Slider {...settings}>
                <div className="slider-box">
                  <p>
                    {" "}
                    <sup>
                      <i class="fa fa-quote-left" style={{ fontSize: 22 }}></i>
                    </sup>
                    &nbsp; Pentru sansa la viata si sanatate. Pentru dreptul
                    uman
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    -Carmen Negoescu
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    România Bucuresti
                  </p>
                </div>
                <div className="slider-box">
                  <p>
                    {" "}
                    <sup>
                      <i class="fa fa-quote-left" style={{ fontSize: 22 }}></i>
                    </sup>
                    &nbsp;Semnez, pentru ca acest tratament este necesar
                    bolnavilor de cancer.
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    -Radu Șleam
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    România Baia Mare
                  </p>
                </div>

                <div className="slider-box">
                  <p>
                    {" "}
                    <sup>
                      <i class="fa fa-quote-left" style={{ fontSize: 22 }}></i>
                    </sup>
                    &nbsp; Semnez pentru ca este absurd ca o planta care poate
                    face atat de mult bine si care poate alina dureri este
                    ilegala, insa este perfect legal sa fim indopati cu pastile
                    care, pe termen lung, pot produce mai mult rau decat bine.
                    Semnez pentru ca este absurd sa fie ilegala o planta care nu
                    a omorat nici macar o persoana, niciodata, insa tutunul si
                    alcoolul, care fac mii de victime anual, sunt legale. Semnez
                    in speranta unei schimbari!
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    -Lucian Constantin Sofronie
                  </p>
                  <p
                    className="text-right"
                    style={{ lineHeight: "8px", fontSize: 14 }}
                  >
                    {" "}
                    România Stefanesti
                  </p>
                </div>
              </Slider>

              <br />
              <div className="row">
                <div className="col-12 my-5">
                  <a
                    href="https://www.petitieonline.com/canabis_in_scop_medicinal_in_romania"
                    target="_blank"
                  >
                    <button className="btn btn-lg text-white">
                      Sign the petition
                    </button>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
