import React from "react";
import Palatul from "../images/palatul.jpg";
import Bio from "./Bio";
import Modal from "./Modal";

export default function Evenimente() {
  const [item, setItem] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";

  const speakers = [
    {
      nameRo: "Prof. Alexandru Vlad Ciurea MD., PhD., MSc., Dr. h.c. Mult.",
      nameEn: "Prof. Alexandru Vlad Ciurea MD., PhD., MSc., Dr. h.c. Mult.",
      img: require("../images/bios/ciurea.jpg"),
      dataRo: `Doctor în Științe Medicale 
       Vicepreședinte Comisiei de neurochirurgie a Ministerului Sănătății din România. Șef de neurochirurgie și director științific, Spitalul Centrului Clinic Sanador, București,  România. 
       Profesor de neurochirurgie, Facultatea de Medicină Universitară "Carol Davila",  București, România. 
       Fondator al Centrului Național de Excelență în Neurochirurgie. 
       Președinte de onoare al Societății Române de Neurochirurgie. 
       Membru al Congresului Chirurgilor Neurologi (SUA). 
       Membru Titular in Cadrul Academiei de Stiinte Medicale din New York, Membru Fondator al Academiei Multidisciplinare Neurotraumatologica  Membru Titular al Academiei Braziliene de Neurochirurgie `,
      dataEn: `Vice-President of the Romanian Ministry of Health Neurosurgical Committee
      Chief of Neurosurgery and Scientific Director, Sanador Clinical Center Hospital, Bucharest, Romania.
      Professor of neurosurgery, "Carol Davila" University School of Medicine, Bucharest, Romania.
      Founder of the National Center for Excellence in Neurosurgery.
      Honorary President of the Romanian Society of Neurosurgery.
      Ex-Vice President of the World Federation of Neurosurgical Societies (WFNS).
      Ex-Chairman of the WFNS Nomminating Committee.
      Representative for Europe in the WFNS Nominating Committee.
      Member of the Congress of Neurological Surgeons (USA).
      `,
      titleEn: ``,
      titleRo: ``,
    },
    {
      nameRo: "Andrew Agius, MD (Malta)",
      nameEn: "Andrew Agius, MD (Malta)",
      img: require("../images/bios/andrewagius.jpg"),
      dataEn:
        "Dr. Andrew Agius is the founder and medical director of The Pain Clinic, an integrative medical center for the management of chronic pain and other chronic diseases that have not responded to conventional medication.",
      dataRo: `Dr. Andrew Agius este fondatorul și directorul medical al The Pain Clinic, un centru  medical integrativ pentru gestionarea durerii cronice și a altor boli cronice care nu au  răspuns la medicația convențională.`,
      titleEn: `Presentation title: "Which cannabis-based treatments work best for chronic pain"`,
      titleRo: `Titlu prezentare: „Ce tratamente pe bază de canabis funcționează cel mai  bine pentru durerea cronică” `,
    },
    {
      nameRo: "Anne Katrin Schlag, Dr, PhD (Marea Britanie)",
      nameEn: "Anne Katrin Schlag, Dr, PhD (UK)",
      img: require("../images/bios/katrin.jpeg"),
      dataEn: `Dr. Anne Katrin Schlag is a psychologist and head of research at Drug Science.
        She completed her doctorate in psychology at the London School of Economics and Political Science, before working as a lecturer at King's College London, where she developed her expertise in the spectrum of science and policy making, risk perception, risk management and risk communication. . She holds honorary scholarships from both Imperial College London and King’s College London and is a scientific advisor to the Cannabis Network for Primary Care.
        In her role at Drug Science, she leads research for the Medical Cannabis Working Group, focusing on medical cannabis controversies, improving patient access, and the continued development of medical cannabis education and stakeholder communication.
        https://drugscience.org.uk
        `,
      dataRo: `Dr. Anne Katrin Schlag este psiholog și șef de cercetare la Drug Science. Și-a absolvit doctoratul în psihologie la London School of Economics and Political  Science, înainte de a lucra ca lector la King’s College din Londra, unde și-a dezvoltat  expertiza în spectrul științei și al elaborării politicilor, percepției riscurilor, gestionării  riscurilor și comunicării riscurilor. Deține burse de onoare atât la Imperial College  London, cât și la King’s College London și este consilier științific la Rețeaua de canabis  pentru îngrijirea primară. În cadrul rolului său la Drug Science, ea conduce cercetările pentru Grupul de lucru  pentru canabisul medical, concentrându-se pe controversele legate de canabisul medical, îmbunătățirea accesului pacienților și dezvoltarea continuă a educației și a  comunicării părților interesate despre canabisul medical. 
        https://drugscience.org.uk `,
      titleRo: `Titlu prezentare: Canabisul medical în Marea Britanie: de la principiu la  practică `,
      titleEn: `Presentation title: Medical cannabis in the UK: from principle to practice`,
    },
    {
      nameRo: "Cosmina Bengescu,Drd, farmacist (RO)",
      nameEn: "Cosmina Bengescu,Drd, Pharmacist (RO)",
      img: require("../images/bengescu.jpeg"),
      dataEn: `Drd. pharmacist Cosmina Bengescu
        Graduate of the University of Medicine and Pharmacy "Carol Davila" Bucharest;
        PhD student of the Faculty of Chemistry within the Polytechnic University, Department of Chemical Engineering / Applied Chemistry;
        Mentor within the project of education and development of students, graduates and educational staff of the University of Medicine and Pharmacy "Carol Davila" Bucharest;
        Founder of Crisia Farm, a pharmacy specialized in preparing personalized treatments.
        `,
      dataRo: `Drd. farmacist Cosmina Bengescu 
        Absolvent al Universitatii de Medicina si Farmacie "Carol Davila" Bucuresti;  Doctorand al Facultatii de Chimie din cadrul Universitatii Politehnice, Sectia Inginerie  Chimica / Chimie aplicata; 
        Mentor in cadrul proiectului de educatie si dezvoltare a studentilor, absolventilor si  personalului educational al Universitatii de Medicina si Farmacie “Carol Davila”  Bucuresti; 
        Fondator al Crisia Farm, farmacie specializata in prepararea tratamentelor  personalizate.
        `,
      titleRo: `Titlu prezentare: Comunicarea în triunghiul relațional medic, pacient și  farmacist - cheia succesului unui tratament personalizat.`,
      titleEn: `Presentation title: Communication in the relational triangle doctor, patient and pharmacist - the key to the success of a personalized treatment.
        `,
    },
    {
      nameRo: "Cristina Sánchez,Dr, PhD (Spania)",
      nameEn: "Cristina Sánchez, Dr, PhD (SPain)",
      img: require("../images/bios/sanchez.jpg"),
      dataEn: `Cristina Sánchez is an associate professor of biochemistry and molecular biology at the Universidad Complutense, Madrid, Spain. Her research focuses on studying the role of the endocannabinoid system in cancer, with a special focus on understanding why cancer cells express this system and how it can be pharmacologically manipulated to obtain therapeutic benefits. She has been working in this field for more than 20 years, and her studies, for example, have shown for the first time that plant cannabinoids produce antitumor responses.
       Cristina is currently vice president of the Spanish Society for Cannabinoid Research, secretary and founding member of the Spanish Observatory on Medicinal Cannabis and vice dean of research at the Complutense University School of Biology.
       `,
      dataRo: `Cristina Sánchez este profesor asociat de biochimie și biologie moleculară la  Universitatea Complutense, Madrid, Spania. Cercetările sale se concentrează pe  studiul rolului sistemului endocanabinoid în cancer, cu accent special în înțelegerea  de ce celulele canceroase exprimă acest sistem și modul în care acesta poate fi  manipulat farmacologic pentru a obține beneficii terapeutice. Lucrează în acest  domeniu de mai bine de 20 de ani, iar studiile ei, de exemplu, au arătat pentru prima  dată că canabinoizii din plante produc răspunsuri antitumorale. 
       Cristina este în prezent vicepreședinte al Societății Spaniole de Cercetare a  Canabinoizilor, secretar și membru fondator al Observatorului spaniol asupra  canabisului medicinal și vice-decan de cercetare al Școlii de Biologie de la  Universitatea Complutense. 
       `,
      titleRo: `Titlu prezentare: Potențialul terapeutic al sistemului endocanabinoid în  cancer `,
      titleEn: `Presentation title: The therapeutic potential of the endocannabinoid system in cancer`,
    },
    {
      nameRo: "David Nutt , DM, FRCP, FRCPsych, FMedSci, DLaws (RO)",
      nameEn: "David Nutt , DM, FRCP, FRCPsych, FMedSci, DLaws (RO)",
      img: require("../images/bios/davidnutt.png"),
      dataEn: `David Nutt is a psychiatrist and Professor Edmond J. Safra of Neuropsychopharmacology in the Division of Brain Sciences, Department of Medicine, Hammersmith Hospital, Imperial College London. His field of research is psychopharmacology.
        He has been President of the European Brain Council, the British Association of Psychopharmacology, the British Neuroscience Association and the European College of Neuropsychopharmacology.
         He is currently the founding president of DrugScience.org.uk
        `,
      dataRo: `David Nutt este psihiatru și profesor Edmond J. Safra de Neuropsihofarmacologie în  Divizia de Științe ale Creierului, Departamentul de Medicină, Spitalul Hammersmith,  Imperial College din Londra. Domeniul său de cercetare este psihofarmacologia. A fost președinte al European Brain Council, al British Association of  Psychopharmacology, al British Neuroscience Association și al European College of  Neuropsychopharmacology.
      În prezent, este președinte fondator al DrugScience.org.uk 18:30 Anne Katrin Schlag, Dr, Phd.(MAREA BRITANIE) `,
      titleRo: `Titlu prezentare: Programul TWENTY21 `,
      titleEn: `Presentation title: TWENTY21 program`,
    },
    {
      nameRo: "Ethan Russo, MD (SUA)",
      nameEn: "Ethan Russo, MD (USA)",
      img: require("../images/bios/ethanrusso.jpg"),
      dataEn: `Ethan Russo, MD, is a neurologist, researcher in psychopharmacology and author. He is the founder and CEO of CReDo Science www.credo-science.com
       He is a Past-President of the International Cannabinoid Research Society and is former Chairman of the International Association for Cannabinoid Medicines. He serves on the Scientific Advisory Board for the American Botanical Council.
       He has consulted or lectured on these topics in 44 US states and Canadian provinces and 44 countries on six continents.
       `,
      dataRo: `Ethan Russo, MD, este neurolog, cercetător în psihofarmacologie și autor. El este  fondatorul și CEO al CReDo Science www.credo-science.com 
      Este fost președinte al Societății Internaționale de Cercetare a Canabinoizilor și fost  președinte al Asociației Internaționale pentru Medicamentele Canabinoide. Face parte  din Consiliul consultativ științific al Consiliului American Botanic. A consultat sau a ținut conferințe despre aceste subiecte în 44 de state americane și  provincii canadiene și 44 de țări de pe șase continente. 
      `,
      titleRo: `Titlu prezentare: Canabinoizi, terpeni și efectul de anturaj `,
      titleEn: `Presentation title: Cannabinoids, terpenes and the entourage effect`,
    },
    {
      nameRo: "Hannah Deacon (MAREA BRITANIE)",
      nameEn: "Hannah Deacon (UK)",
      img: require("../images/bios/hannahdeacon.jpg"),
      dataEn: `Hannah Deacon is the mother of Alfie and Annie and Drew's partner.
      Alfie began to have seizures at the age of eight months, which began to get worse with age. He is diagnosed with PCDH19.
      The campaign by Hannah and End Our Pain has played a huge role in ensuring law change in the UK and access to cannabis treatment.
      She is the director of the Society of Medical Cannabis Clinics and also of the Maple Tree Medical Cannabis Consultants.
      `,
      dataRo: `Hannah Deacon este mama lui Alfie și Annie și partenera lui Drew. Alfie a început să aibă convulsii la vârsta de opt luni, convulsii care s-au înrăutățit  progresiv odată cu vârsta. El este diagnosticat cu PCDH19. 
      Campania desfășurată de Hannah și End Our Pain a jucat un rol imens în asigurarea  schimbării legii în Marea Britanie și accesul la tratamentul pe bază de canabis.  Este director al Societății Clinicilor de Canabis Medical și, de asemenea, al  Consultanților Medicali de Canabis din Maple Tree. 
      `,
      titleRo: `Titlu prezentare: Cum a salvat viața fiului meu canabisul medical `,
      titleEn: `Presentation title: How medical cannabis saved my son's life`,
    },
    {
      nameRo: "Profesorul Michael P Barnes MD FRCP (MAREA BRITANIE)",
      nameEn: "Profesorul Michael P Barnes MD FRCP (UK)",
      img: require("../images/bios/barnes.jpg"),
      dataEn: `Honorary professor of neurological rehabilitation
      Professor Barnes is a neurologist and professor of neurological rehabilitation at the University of Newcastle, UK. He was the founding president of the World Federation of Neurological Rehabilitation. He set the Academy of Medical cannabis as a teaching course for doctors, established the first UK cannabis clinic and is Chair of the UK Medical Cannabis Clinicians Society
      `,
      dataRo: `Profesor onorific de reabilitare neurologică 
      Profesorul Barnes este neurolog și profesor de reabilitare neurologică la Universitatea  din Newcastle, Marea Britanie. A fost președinte fondator al Federației Mondiale de 
      Reabilitare Neurologică. A înființat Academia de canabis medical ca un curs de  predare pentru medici, a înființat prima clinică de canabis din Marea Britanie și este  președintele Societății medicilor de canabis din Marea Britanie. 
      `,
      titleRo: `Titlu prezentare: „Indicații neurologice pentru canabis” `,
      titleEn: `Presentation title: "Neurological indications for cannabis"`,
    },
    {
      nameRo: "Sita Schubert (Germania)",
      nameEn: "Sita Schubert (Germany)",
      img: require("../images/bios/sita.jpg"),
      dataEn: `Lawyer Secretary General European Medicinal Cannabis Association (EUMCA), Brussels
       Managing Director COMM-OP Europe
       Founding Member of Healthcare Business Network “Female Leaders in Healthcare”
       `,
      dataRo: `Avocat 
       Secretar general European Medicinal Cannabis Association (EUMCA), Bruxelles Director general COMM-OP Europe 
       Membru fondator al Rețelei de afaceri în domeniul sănătății „Femei lideri în domeniul  sănătății” 
       `,
      titleRo: `Titlu prezentare: Accesul la canabis pentru pacienți - obstacole legale și  șanse în modelul german`,
      titleEn: `Presentation title: Access to cannabis for patients - legal obstacles and chances in the German model`,
    },
    {
      nameRo: "Prof.dr.Valeriu Tabără (RO)",
      nameEn: "Prof.dr.Valeriu Tabără (RO)",
      img: require("../images/bios/valeriu.jpg"),
      dataEn: `He graduated from the Faculty of Agronomy of the Timisoara Agronomic Institute,
       Doctor of Agronomy.
        Currently, the President of the Academy of Agricultural and Forestry Sciences „Gheorghe Ionescu-Șișești‟.
       Creator of four varieties of dioecious hemp registered in the official list of national and EU varieties.
        Vice-Chairman and Chairman of the Committee on Agriculture of the Chamber of Deputies, Minister of Agriculture and Food (1994 - 1996) and Minister of Agriculture and Rural Development (2010 - 2012).
       `,
      dataRo: `A absolvit Facultatea de Agronomie a Institutului Agronomic Timișoara,  Doctor în agronomie. 
       În prezent, Președintele Academiei de Științe Agricole și Silvice „Gheorghe Ionescu Șișești‟. 
       Creator a patru soiuri de cânepă dioică înscrise în lista oficială de soiuri naționala și  a UE. 
       Vicepreședinte și Președinte al Comisiei de Agricultură din Camera Deputaților,  Ministru al Agriculturii și Alimentației (1994 – 1996) și Ministru al Agriculturii și  Dezvoltării Rurale (2010 – 2012). 
       `,
      titleRo: `Titlu prezentare: Cânepa și economia `,
      titleEn: `Presentation title: Hemp and the economy`,
    },
    {
      nameRo: "Cosmin Muresan (RO)",
      nameEn: "Cosmin Muresan (RO)",
      img: require("../images/bios/muresan.jpg"),
      dataEn: `With 8 years of experience in the hemp industry, Cosmin has experienced first-hand legal issues with national and international legislation in agriculture, processing, import, export and marketing of hemp-based products. Since 2017, operations and installations manager for the only extraction company in Romania - PBG Global. He consulted the association Victoria Mea in order to harmonize the Romanian legislation with the European legislation, through the proposed amendments to the draft Law (PL-x no. 631/2019) on the legal regime of the cannabis plant, of substances and preparations containing cannabis, used for medical purposes`,
      dataRo: `Cu o experiență de 8 ani în industria cânepei, Cosmin a experimentat din prima mână  problemele legale cu legislația națională și internațională din agricultură, prelucrare,  import, export și comercializare produse pe bază de cânepă. Din 2017, manager de  operațiuni și instalații pentru singura companie de extracție din România - PBG  Global. A consultat asociația Victoria Mea pentru a armoniza legislația română cu  legislația europeană, prin amendamentele propuse la proiectul de Lege( PL-x nr.  631/2019 ) privind regimul juridic al plantei canabis, al substanţelor şi preparatelor  ce conţin canabis, utilizate în scop medical`,
      titleRo: `Titlu prezentare: Statutul juridic al canabinoizilor în UE și explicarea  amendamentelor propuse în numele Asociației Victoria mea `,
      titleEn: `Presentation title: Legal status of cannabinoids in the EU and explanation of proposed amendments on behalf of  Victoria Mea Association`,
    },
    {
      nameRo: "Ervin Ivanov MD, Phd (BULGARIA)",
      nameEn: "Ervin Ivanov MD, Phd (BULGARIA)",
      img: require("../images/bios/ivanov.jpg"),
      dataEn: `Dr. Ervin Ivanov is part of the management group of the International Coalition of Medical Cannabis Users (IMCPC).
      He has hands-on experience and follow-up with patients from different EU countries who use cannabinoids.
     Head of research at the only legal THC and Marijuana legal laboratory in Bulgaria, he conducts in-vitro, in-vivo and clinical studies with cannabinoids.
     `,
      dataRo: `Dr. Ervin Ivanov face parte din grupul de management al Coaliției Internaționale a  Pacienților utilizatori de Cannabis Medical (IMCPC). 
     Are experiență practică și de follow-up cu pacienți din diferite țări ale UE care  utilizează canabinoizi.  
     Șef de cercetare al singurului laborator legal de THC și Marijuana din Bulgaria și  desfășoară studii in-vitro, in-vivo și clinice cu canabinoizi.
     `,
      titleRo: `Titlu prezentare: Utilizarea canabidiolului în medicină `,
      titleEn: `Presentation title: The use of cannabidiol in medicine`,
    },
    {
      nameRo: "Ileana Cristina Dumitrache",
      nameEn: "Ileana Cristina Dumitrache",
      img: require("../images/bios/ileana.jpeg"),
      dataEn: ` `,
      dataRo: ` `,
      titleRo: `Deputat, initiator proiect de lege`,
      titleEn: `Deputat, initiator proiect de lege`,
    },
    {
      nameRo: "Alexandra Bivolu-Cârstea",
      nameEn: "Alexandra Bivolu-Cârstea",
      img: require("../images/bios/alexandra.jpeg"),
      dataEn: ` `,
      dataRo: ` `,
      titleRo: `Președinte Asociația Victoria Mea`,
      titleEn: `President of the Victoria Mea Association`,
    },
  ];

  const showModal = (data) => {
    setItem(data);
    setOpen(true);
  };

  const dismissModal = () => {
    setOpen(false);
    setItem(null);
  };

  return (
    <div>
      <a id="evenimente"></a>
      <section className="events pt-5 d-flex justify-content-center align-items-center ">
        <div
          className="container"
          style={{ background: "rgba(255,255,255,0.8)", padding: "10px" }}
        >
          {lang == "ro" ? (
            <div className="w-100">
              <h2 className="text-center mb-5">Evenimente</h2>
              <div className="row">
                <div className="col-12 text-center order-2 align-self-center mt-3 text-center">
                  <ul className="list-unstyled">
                    <li>
                      Conferința internațională :{" "}
                      <strong>„Utilizarea canabisului medicinal”</strong>
                    </li>
                    <li> Marți,23 februarie, 2021, ora 14:00</li>
                    <li>
                      Urmareste live evenimentul pe{" "}
                      <a
                        href="https://youtube.com/channel/UCXy19Lp_LV2wK8v7k6om5qQ"
                        target="_blank"
                      >
                        YouTube
                      </a>{" "}
                      si{" "}
                      <a
                        href="https://www.facebook.com/canabisromania/"
                        target="_blank"
                      >
                        Facebook
                      </a>{" "}
                    </li>
                    <br />
                    <li>
                      Conferința științifică organizata <br /> cu ajutorul
                      doamnei deputat Ileana Cristina Dumitrahe, Camera
                      Deputatilor, Parlamentul Romaniei <br />
                      Moderator: Alexandra Bivolu-Cârstea, presedinte Asociatia
                      Victoria Mea
                    </li>
                    <br />

                    <li>
                      <a href="/AgendaRomana.pdf" download>
                        Agenda de Conferință
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 order-1 ">
                  <img
                    src={Palatul}
                    className="img-fluid events-image"
                    alt="Palatul Parliamentului Eveniment"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <strong
                    style={{ textDecoration: "underline", fontSize: "18px" }}
                  >
                    Speakeri
                  </strong>
                </div>
                {speakers.map((item, index) => {
                  return (
                    <div className="col-6 col-md-4 col-lg-3">
                      <Bio
                        item={item}
                        key={index}
                        lang={lang}
                        showModal={() => showModal(item)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="w-100">
              <h2 className="text-center mb-5">Events</h2>
              <div className="row">
                <div className="col-12 text-center pt-3 order-2 align-self-center mt-3 mt-lg-0">
                  <ul className="list-unstyled">
                    <li>
                      International Conference :{" "}
                      <strong>„Use of Medicinal Cannabis”</strong>
                    </li>
                    <li>
                      {" "}
                      Tuesday, February 23<sup>rd</sup>, 2021, 2:00 p.m. (UTC+2)
                    </li>
                    <li>
                      Watch the event live on{" "}
                      <a
                        href="https://youtube.com/channel/UCXy19Lp_LV2wK8v7k6om5qQ"
                        target="_blank"
                      >
                        YouTube
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.facebook.com/canabisromania/"
                        target="_blank"
                      >
                        Facebook
                      </a>{" "}
                    </li>
                    <li>
                      A scientific debate held with the support of Mrs. Ileana
                      Cristina Dumitrache
                    </li>
                    <li>Chamber of Deputies, Parliament of Romania </li>
                    <li>
                      Moderator: Alexandra Bivolu-Cârstea, President of Victoria
                      Mea Association{" "}
                    </li>
                    <br />
                    <li>
                      <a href="/AgendaEnglish.pdf" download>
                        Agenda of the Conference
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 order-1 ">
                  <img
                    src={Palatul}
                    className="img-fluid events-image"
                    alt="Palatul Parliamentului Eveniment"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <strong
                    style={{ textDecoration: "underline", fontSize: "18px" }}
                  >
                    Speakers
                  </strong>
                </div>
                {speakers.map((item, index) => {
                  return (
                    <div className="col-6 col-md-4 col-lg-3">
                      <Bio
                        item={item}
                        key={index}
                        lang={lang}
                        showModal={() => showModal(item)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </section>

      {isOpen && item && (
        <Modal isOpen={isOpen} data={item} dismissModal={dismissModal}>
          <div className="modal-header">
            {lang == "ro" ? item.nameRo : item.nameEn}
            <span onClick={dismissModal} className="cursor-pointer">
              <i className="fa fa-times"></i>
            </span>
          </div>
          <div className="modal-body">
            <img className="modal-bio-image" src={item.img.default} />
            <p className="text-center">
              <strong>{lang == "ro" ? item.titleRo : item.titleEn}</strong>
            </p>
            <p>{lang == "ro" ? item?.dataRo : item?.dataEn}</p>
          </div>
        </Modal>
      )}
    </div>
  );
}
