import React from "react";

export default function TopSection() {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";

  return (
    <section className="banner d-flex align-items-center">
      <div
        className="container mt-3"
        style={{ background: "rgba(255,255,255,0.7)" }}
      >
        <div className="row align-items-center">
          <div className="col-12 py-3 px-md-5" style={{ color: "#484545" }}>
            {lang == "ro" ? (
              <>
                <h1 className="text-center my-3">
                  Canabis medicinal în România
                </h1>
                <p
                  className="text-justify"
                  style={{ lineHeight: "2.5", fontSize: "18px" }}
                >
                  Campania și petiția națională pentru legalizarea canabisului,
                  în scop medicinal, în România, sunt inițiate și desfășurate de
                  Asociația „Victoria Mea”, care s-a înființat cu scopul
                  demarării, susținerii și desfășurării unor programe și
                  proiecte în domeniul sănătății, apărării dreptului la viață și
                  la tratament al românilor. Primele proiecte susținute de
                  asociația „Victoria Mea” sunt: legalizarea canabisului
                  medicinal, promovarea aplicabilității terapiilor pe bază de
                  canabis, susținerea educației și informării corecte ale
                  pacienților, pregătirea continuă a medicilor în acest domeniu
                  nou și dinamic, precum și încurajarea studiului și cercetării
                  științifice în domeniul canabisului medicinal, pentru
                  demonstrarea beneficiilor aduse pacienților, susținerea și
                  încurajarea dezvoltării cercetării în acest domeniu în România
                  pentru ca și țara noastră să contribuie la aportul pe care
                  știința îl aduce la nivel mondial, în beneficiul pacienților,
                  uniformizarea politicilor naționale din domeniu cu politicile
                  europene și schimbul de informații, accesul la terapii și
                  tratamente pe bază de canabis ca și în alte state europene și
                  din întreaga lume, acolo unde canabisul deja a căpătat
                  recunoașterea binemeritată.
                </p>
              </>
            ) : (
              <>
                <h1 className="text-center my-3">
                  Medicinal cannabis in Romania
                </h1>
                <p
                  className="text-justify"
                  style={{ lineHeight: "2.5", fontSize: "18px" }}
                >
                  The campaign and the national petition for the legalization of
                  cannabis, for medicinal purposes, in Romania, are initiated
                  and carried out by the "Victoria Mea" Association, which was
                  established to start, support and carry out programs and
                  projects in the field of health, protection of the right of
                  living and health management of Romanians. The first projects
                  supported by the association "Victoria Mea" are: legalization
                  of medical cannabis, promoting the applicability of
                  cannabis-based therapies, supporting the correct education and
                  information of patients, continuous training of doctors in
                  this new and dynamic field, and encouraging study and
                  scientific research in the field of medical cannabis, to
                  demonstrate the benefits to patients, to support and encourage
                  the development of research in this field in Romania for our
                  country to contribute to the development that science brings
                  worldwide, for the benefit of patients, standardizing national
                  policies with European policies and the exchange of
                  information, access to cannabis-based therapies and treatments
                  as in other European countries and around the world, where
                  cannabis has already received well-deserved recognition.
                </p>
              </>
            )}
          </div>
          <div className="col-6"></div>
        </div>
      </div>
    </section>
  );
}
