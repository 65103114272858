import React, { useState, useEffect } from "react";
import Modal from "./Modal";
function Bio(props) {
  return (
    <>
      <div className="bio card my-3">
        <img src={props?.item?.img?.default} />
        <p>{props.lang == "ro" ? props?.item?.nameRo : props?.item?.nameEn}</p>
        {props.item.dataEn && props.item.dataRo && (
          <a onClick={props.showModal}>Click to read more</a>
        )}
      </div>
    </>
  );
}

export default React.memo(Bio);
