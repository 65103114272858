import React from "react";
import Alexandra from "../images/alexandraDp1.jpeg";
import Briefcase from "../images/briefcase.png";

export default function Presidente() {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";
  return (
    <div>
      <a id="presidente-fondator"></a>
      <section className="our-members d-flex pt-5 justify-content-center align-items-center">
        <div
          className="container"
          style={{
            background: "rgba(255,255,255,0.8)",
            color: "black",
            borderRadius: "5px",
          }}
        >
          {lang == "ro" ? (
            <div className="w-100">
              <p className="text-center pt-3">
                <img width="50px" src={Briefcase} />
              </p>
              <h2 className="text-center my-3">Președinte Fondator</h2>
              <div className="row">
                <div className="col-12 text-center my-3">
                  <img
                    src={Alexandra}
                    className="border-rounded president-profile"
                  />
                  <p className="quote">
                    „Victoria mea este Victoria noastră, a celor care decidem să
                    ne informăm și să facem alegerile corecte pentru
                    îmbunățățirea calității vieților noastre!”
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p>
                    Alexandra a început această campanie pentru legalizarea
                    canabisului medicinal în România, pe când lupta pentru
                    salvarea vieții mamei ei, Victoria. În prezent, proiectul
                    legislativ aflat pe agenda viitoarelor dezbateri din
                    Parlamentul României poartă, simbolic, numele „Victoria”, în
                    memoria mamei ei și ca un simbol al VICTORIEI pe care
                    pacienții români o merită, la fel ca alți pacienți din
                    Europa și din lume.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100">
              <p className="text-center pt-3">
                <img width="50px" src={Briefcase} />
              </p>
              <h2 className="text-center my-3">Founding President</h2>
              <div className="row">
                <div className="col-12 text-center my-3">
                  <img
                    src={Alexandra}
                    className="border-rounded president-profile"
                  />
                  <p className="quote">
                    „Victoria mea/ My Victory is our Victory, of those who decide to inform theirselves and make the right choices to improve their quality of lives!”
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p>
                  Alexandra started this campaign to legalize medical cannabis in Romania, while fighting to save the life of her mother, Victoria. Currently, the legislative project on the agenda of future debates in the Romanian Parliament is symbolically named "Victoria" in memory of her mother and as a symbol of VICTORY that Romanian patients deserve, like other patients in Europe and the world.

                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
