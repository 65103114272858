import React from "react";
import VictoriaMea from "../images/logo.png";
import Shop from "../images/shop.jpg";

export default function Footer() {
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";

  return (
    <div>
      <footer className="bg-white">
        <div className="container-fluid p-md-5">
          {lang == "ro" ? (
            <div className="row">
              <div className="col-12 col-md-4 mt-3 mt-lg-0">
                <h4 className="mb-4">
                  <span>Victoria Mea</span>
                </h4>
                <p className="text-justify">
                  Campania și petiția națională pentru legalizarea canabisului,
                  în scop medical, în România, sunt inițiate și desfășurate de
                  Asociația Victoria Mea, care s-a înființat cu scopul
                  demarării, susținerii și desfășurării unor programe și
                  proiecte în domeniul sănătății, apărării dreptului la viață și
                  la tratament al românilor.
                </p>
              </div>
              <div className="col-12 col-md-3 offset-lg-1 mt-3 mt-lg-0">
                <h4 className="mb-4">
                  <span>Useful Links</span>
                </h4>
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Acasă</a>
                  </li>
                  <li>
                    <a href="#despre-noi">Despre Noi</a>
                  </li>
                  <li>
                    <a href="#presidente-fondator">Președinte Fondator</a>
                  </li>
                  <li>
                    <a href="#">Canabis Medicinal</a>
                  </li>
                  <li>
                    <a href="#evenimente">Evenimente</a>
                  </li>
                  <li>
                    <a href="">Articole și linkuri științifice</a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-4 mt-3 mt-lg-0">
                <h4 className="mb-4">
                  <span>Contact</span>
                </h4>
                <a href="mailto:canabismedicinalro@gmail.com">
                  canabismedicinalro@gmail.com
                </a>
                <br />
                <a href="mailto:contact@canabisromania.ro">
                  contact@canabisromania.ro
                </a>
                <br />
                <a href="mailto:alexandra@canabisromania.ro">
                  alexandra@canabisromania.ro
                </a>
                <br />
                <br /> Contacteaza-ne&nbsp; pe Facebook aici:{" "}
                <a
                  href="https://www.facebook.com/canabisromania"
                  target="_blank"
                >
                  <i
                    className="fa fa-2x fa-facebook-square"
                    aria-hidden="true"
                  ></i>
                </a>
                <br />
                <br />
                Urmăriți videoclipurile noastre aici:{" "}
                <a
                  href="https://www.youtube.com/channel/UCXy19Lp_LV2wK8v7k6om5qQ"
                  target="_blank"
                >
                  <i className="fa fa-2x fa-youtube" aria-hidden="true"></i>
                </a>
              </div>
              <div className="col-12 mt-5 mt-md-2">
                <h4 className="">
                  <span>Partenerii</span>
                  <div>
                    <img src={VictoriaMea} width="150" alt="Victoria Mea" />{" "}
                    &nbsp;
                    <a href="https://cannabis-medicinal.ro/" target="_blank">
                      <img src={Shop} alt="https://cannabis-medicinal.ro/" />
                    </a>
                  </div>
                </h4>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 col-md-4 mt-3 mt-lg-0">
                <h4 className="mb-4">
                  <span>Victoria Mea</span>
                </h4>
                <p className="text-justify">
                  The campaign and the national petition for the legalization of
                  cannabis, for medical purposes, in Romania, are initiated and
                  carried out by Victoria Mea Association, which was set up to
                  start, support and carry out programs and projects in the
                  field of health, protection of the right to life and treatment
                  of Romanians.
                </p>
              </div>
              <div className="col-12 col-md-3 offset-lg-1 mt-3 mt-lg-0">
                <h4 className="mb-4">
                  <span>Useful Links</span>
                </h4>
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#despre-noi">About Us</a>
                  </li>
                  <li>
                    <a href="#presidente-fondator">Founding President</a>
                  </li>
                  <li>
                    <a href="#">Medicinal Cannabis</a>
                  </li>
                  <li>
                    <a href="#evenimente">Events</a>
                  </li>
                  <li>
                    <a href="">Articles and Scientific Links</a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-4 mt-3 mt-lg-0">
                <h4 className="mb-4">
                  <span>Contact</span>
                </h4>
                <a href="mailto:canabismedicinalro@gmail.com">
                  canabismedicinalro@gmail.com
                </a>
                <br />
                <a href="mailto:contact@canabisromania.ro">
                  contact@canabisromania.ro
                </a>
                <br />
                <a href="mailto:alexandra@canabisromania.ro">
                  alexandra@canabisromania.ro
                </a>
                <br />
                <br /> Contact us on Facebook:{" "}
                <a
                  href="https://www.facebook.com/canabisromania"
                  target="_blank"
                >
                  <i
                    className="fa fa-2x fa-facebook-square"
                    aria-hidden="true"
                  ></i>
                </a>
                <br />
                <br />
               Watch our videos here:{" "}
                <a
                  href="https://www.youtube.com/channel/UCXy19Lp_LV2wK8v7k6om5qQ"
                  target="_blank"
                >
                  <i className="fa fa-2x fa-youtube" aria-hidden="true"></i>
                </a>
              </div>
              <div className="col-12 mt-5 mt-md-2">
                <h4 className="">
                  <span>Partners</span>
                  <div>
                    <img src={VictoriaMea} width="150" alt="Victoria Mea" />{" "}
                    &nbsp;
                    <a href="https://cannabis-medicinal.ro/" target="_blank">
                      <img src={Shop} alt="https://cannabis-medicinal.ro/" />
                    </a>
                  </div>
                </h4>
              </div>
            </div>
          )}
        </div>
      </footer>
    </div>
  );
}
