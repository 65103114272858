import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
// import Loader from "../images/loader.gif";
const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  // centerMode: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        // dots: true,
      },
    },
    {
      breakpoint: 565,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        // dots: true,
      },
    },
  ],
};
export default function YoutubeList({ selectVideo }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData()
      .then((res) => {
        console.log(res.data.items);
        setData(res.data.items);
        selectVideo(res.data.items[0]?.id?.videoId);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchData = () => {
    return axios.get("https://www.googleapis.com/youtube/v3/search", {
      params: {
        part: "snippet",
        key: "AIzaSyBtHIZDQQffWWaEdd8odQSop2EfFPY_kQs",
        maxResults: 50,
        channelId: "UCXy19Lp_LV2wK8v7k6om5qQ",
        order: "date",
        videoEmbeddable: "true",
        type: "video",
      },
    });
  };

  if (loading) {
    return (
      <div>{/* <img src={Loader} alt="" width="100" height="100" /> */}</div>
    );
  } else {
    return (
      <Slider {...settings}>
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className="video-box bg-white"
              onClick={() => selectVideo(item?.id?.videoId)}
            >
              <img src={item?.snippet?.thumbnails?.medium?.url} />
              <p>{item?.snippet?.title}</p>
            </div>
          );
        })}
      </Slider>
    );
  }
}
