import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import Header from "./Components/header";
import "./App.css";
import TopSection from "./Components/TopSection";
import DespreNoi from "./Components/DespreNoi";
import Presidente from "./Components/Presidente";
import Evenimente from "./Components/Evenimente";
import ContactUs from "./Components/ContactUs";
import Footer from "./Components/Footer";
import MediaSection from "./Components/MediaSection";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ContactForm from "./Components/ContactForm";
import Petiti from "./Components/Petiti";
import Cursuri from "./Components/Cursuri";
import Fade from "react-reveal/Fade";
import YoutubePopup from "./Components/YoutubePopup";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Homepage} />
      </Switch>
    </BrowserRouter>
  );
}

const Homepage = () => {
  return (
    <>
      <Header />
      <YoutubePopup />
      <Fade>
        <TopSection />
      </Fade>
      <Fade bottom>
        <DespreNoi />
      </Fade>
      <Fade bottom>
        <Presidente />
      </Fade>
      <Fade bottom>
        <Petiti />
      </Fade>
      <Fade bottom>
        <Evenimente />
      </Fade>
      <Fade bottom>
        <MediaSection />
      </Fade>
      <Fade bottom>
        <Cursuri />
      </Fade>

      <Fade bottom>
        <ContactForm />
      </Fade>

      <Footer />
    </>
  );
};

export default App;
