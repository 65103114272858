import React, {useState} from "react";
import Articole from "./Articole";
import YoutubeList from "./YoutubeList";

export default function MediaSection() {

const [activeLink, setActiveLink] = useState('')
  const selectVideo = (item) => {
    setActiveLink('https://www.youtube.com/embed/' + item )
  }


  return (
    <div className="contact-us pt-5  bg-lightgrey">
      <a id="media"></a>
      <div className="container media-container mb-3" style={{background:'rgba(255,255,255,0.8)', padding:'10px'}}>
        <h2 className="text-center mb-5">
          Media{" "}
          {/* <a href="/media.docx" download>
            <i
              className="fa fa-paperclip"
              style={{ width: "35px" }}
              aria-hidden="true"
            ></i>
          </a> */}
        </h2>

        <iframe
          width="560"
          height="315"
          src={activeLink}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        <br />
        <br />
        <YoutubeList selectVideo={selectVideo}/>

        <Articole />
      </div>
    </div>
  );
}

