import React, { useState, useEffect } from "react";
import Modal from "./Modal";

export default function YoutubePopup() {
  const [showModal, setModal] = useState(false);
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "ro";
  useEffect(() => {
    let seen = localStorage.getItem("seen");
    if (seen) {
      setModal(seen == true ? false : true);
    } else {
      localStorage.setItem("seen", false);
      setModal(true);
    }
  }, []);

  const dismissModal = () => {
    setModal(false);
    localStorage.setItem("seen", true);
  };

  return (
    <div className="youtube-pop">
      <Modal isOpen={showModal} dismissModal={dismissModal}>
        <div className="modal-header">
          <span className="ml-auto">
            <i className="fa fa-times" onClick={dismissModal}></i>
          </span>
        </div>

        <div className="modal-body">
          {lang == "ro" ? (
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/1QfAQyWnKiI"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ) : (
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/_UhSFgA6_NM"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </Modal>
    </div>
  );
}
