import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

export default function Modal({ isOpen, dismissModal, children }) {
  let wrapperRef = React.useRef();

  if (isOpen) {
    return (
      <div className="custom-modal-backdrop">
        <Fade top>
          <div ref={wrapperRef}>
            <div className="custom-modal">
               {children}
            </div>
          </div>
        </Fade>
      </div>
    );
  } else return "";
}
