import React, { useState, useEffect } from "react";
import ProfileFb from "../images/Profil_fb.png";

const Header = () => {
  const [lang, setLang] = useState("ro");

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "ro");
      setLang("ro");
    } else {
      setLang(localStorage.getItem("lang"));
    }
  }, []);

  return (
    <nav className="navbar navbar-expand-sm navbar-light sticky-top shadow-lg">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={ProfileFb} className="img-fluid" width="80px" />
        </a>
        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavId"
          aria-controls="collapsibleNavId"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavId">
          {lang == "ro" ? (
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 justify-content-around d-flex w-100">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Acasă
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#despre-noi">
                  Despre Noi
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Canabis Medicinal
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#presidente-fondator">
                  Președinte Fondator
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#evenimente">
                  Evenimente
                </a>
              </li>
              {/* <li className="nav-item">
            <a className="nav-link" href="#contact-us">
              Contact Us
            </a>
          </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#media">
                  Media
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.petitieonline.com/canabis_in_scop_medicinal_in_romania?fbclid=IwAR2qMAruM_t5YI0Je1xZRa3Llrd2NlkmuD9xFqpVVScZ0foJO3l7ZE4RBs0"
                  target="_blank"
                >
                  Petiția
                </a>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 justify-content-around d-flex w-100">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#despre-noi">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Medicinal Cannabis
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#presidente-fondator">
                  Founding President
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#evenimente">
                  Events
                </a>
              </li>
              {/* <li className="nav-item">
          <a className="nav-link" href="#contact-us">
            Contact Us
          </a>
        </li> */}
              <li className="nav-item">
                <a className="nav-link" href="#media">
                  Media
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.petitieonline.com/canabis_in_scop_medicinal_in_romania?fbclid=IwAR2qMAruM_t5YI0Je1xZRa3Llrd2NlkmuD9xFqpVVScZ0foJO3l7ZE4RBs0"
                  target="_blank"
                >
                  The Petition
                </a>
              </li>
            </ul>
          )}

          <span className="lang-control">
            <a
              onClick={() => {
                localStorage.setItem("lang", "ro");
                window.location.reload();
              }}
              className={localStorage.getItem("lang") == "ro" ? "active" : ""}
            >
              RO
            </a>{" "}
            |{" "}
            <a
              onClick={() => {
                localStorage.setItem("lang", "en");
                window.location.reload();
              }}
              className={localStorage.getItem("lang") == "en" ? "active" : ""}
            >
              EN
            </a>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Header;
